<template>
  <div class="flex flex-col lg:flex-row not-prose">
    <div class="flex-auto mr-1">
      <h2 class="dark:text-gray-200 text-center text-3xl font-bold mb-2">
        Boys
      </h2>
      <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-800 text-xs font-medium dark:text-white text-gray-500 tracking-wider table-fixed">
        <thead class="bg-gray-50 dark:bg-black dark:bg-opacity-50 text-left">
          <tr>
            <th scope="col" class="px-6 py-3 w-24">
              Event
            </th>
            <th scope="col" class="px-6 py-3 w-48">
              Athlete
            </th>
            <th scope="col" class="px-6 py-3 w-20">
              Performance
            </th>
            <th scope="col" class="px-6 py-3">
              Date
            </th>
          </tr>
        </thead>
        <tbody class="text-left">
          <tr>
            <td colspan="4" class="px-6 py-3 border-t-2 dark:border-black border-gray-200 text-center dark:bg-green-800 dark:bg-opacity-50 bg-gray-300 bg-opacity-50">
              Outdoor
            </td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">100M</td>
            <td class="px-6 py-3">Blake Wilson</td>
            <td class="px-6 py-3">00:10.83</td>
            <td class="px-6 py-3">2021-04-16</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">200M</td>
            <td class="px-6 py-3">B. Heffelfinger</td>
            <td class="px-6 py-3">00:21.60</td>
            <td class="px-6 py-3">1962-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">400M</td>
            <td class="px-6 py-3">Vince Beebe</td>
            <td class="px-6 py-3">00:49.66</td>
            <td class="px-6 py-3">2000-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">800M</td>
            <td class="px-6 py-3">Dave Freeland</td>
            <td class="px-6 py-3">01:52.40</td>
            <td class="px-6 py-3">1990-06-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">1600M</td>
            <td class="px-6 py-3">Willy Wiley</td>
            <td class="px-6 py-3">04:15.90</td>
            <td class="px-6 py-3">1987-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">3200M</td>
            <td class="px-6 py-3">D. Sturgeon</td>
            <td class="px-6 py-3">09:32.60</td>
            <td class="px-6 py-3">1986-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">110M Hurdles</td>
            <td class="px-6 py-3">D. Brinker</td>
            <td class="px-6 py-3">00:14.70</td>
            <td class="px-6 py-3">1980-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">300M Hurdles</td>
            <td class="px-6 py-3">Cory Beebe</td>
            <td class="px-6 py-3">00:38.51</td>
            <td class="px-6 py-3">2005-05-26</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">4x100M Relay</td>
            <td class="px-6 py-3">
              Chris Casteel<br />
              Russell Crall<br />
              C. Hortsman<br />
              T. Reed
            </td>
            <td class="px-6 py-3">00:43.80</td>
            <td class="px-6 py-3">2002-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">4x200M Relay</td>
            <td class="px-6 py-3">
              Chris Casteel<br />
              Russell Crall<br />
              C. Hortsman<br />
              T. Reed
            </td>
            <td class="px-6 py-3">01:31.70</td>
            <td class="px-6 py-3">2002-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">4x400M Relay</td>
            <td class="px-6 py-3">
              M. Dilday<br />
              T. Nichols<br />
              J. Stahl<br />
              Willy Wiley
            </td>
            <td class="px-6 py-3">03:25.00</td>
            <td class="px-6 py-3">1987-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">4x800M Relay</td>
            <td class="px-6 py-3">
              B. Garner<br />
              S. Kelly<br />
              J. Smith<br />
              D. Wood
            </td>
            <td class="px-6 py-3">07:57.90</td>
            <td class="px-6 py-3">1986-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">4x1600M Relay</td>
            <td class="px-6 py-3">
              M. Garbaccio<br />
              T. Neura<br />
              D. Sturgeon<br />
              Willy Wiley
            </td>
            <td class="px-6 py-3">18:22.30</td>
            <td class="px-6 py-3">1986-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Sprint Medley</td>
            <td class="px-6 py-3">
              Andrew Hibler<br />
              Michael Williams<br />
              Blake Wilson<br />
              Luke Yuravak
            </td>
            <td class="px-6 py-3">01:38.25</td>
            <td class="px-6 py-3">2021-04-10</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">Distance Medley</td>
            <td class="px-6 py-3">
              B. Hahn<br />
              M. Lang<br />
              S. Murphy<br />
              S. Yatson
            </td>
            <td class="px-6 py-3">10:36.70</td>
            <td class="px-6 py-3">1982-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">4x110M Shuttle Hurdles</td>
            <td class="px-6 py-3">
              Mike Brodwolf<br />
              K. Fiala<br />
              K. Seymore<br />
              D. Taylor
            </td>
            <td class="px-6 py-3">01:02.90</td>
            <td class="px-6 py-3">2002-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">4x300M Shuttle Hurdles</td>
            <td class="px-6 py-3">
              Jonathan Hearn<br />
              Chandler Laux<br />
              Zach Miglich<br />
              Clay Vondriska
            </td>
            <td class="px-6 py-3">03:13.01</td>
            <td class="px-6 py-3">2011-03-26</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">4x100M Weight Relay</td>
            <td class="px-6 py-3">
              Mark Andrews<br />
              Dylan Craig<br />
              Corey Jenkin<br />
              Jacob Scheck
            </td>
            <td class="px-6 py-3">00:52.73</td>
            <td class="px-6 py-3">2015-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">High Jump</td>
            <td class="px-6 py-3">J. Korn</td>
            <td class="px-6 py-3">06-07.00</td>
            <td class="px-6 py-3">1990-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Long Jump</td>
            <td class="px-6 py-3">T. Bittner</td>
            <td class="px-6 py-3">21-11.00</td>
            <td class="px-6 py-3">1975-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">Pole Vault</td>
            <td class="px-6 py-3">Jake Lile</td>
            <td class="px-6 py-3">13-06.00</td>
            <td class="px-6 py-3">2007-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Shot Put</td>
            <td class="px-6 py-3">Joel Belding</td>
            <td class="px-6 py-3">57-03.75</td>
            <td class="px-6 py-3">2004-06-06</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">Discus</td>
            <td class="px-6 py-3">Cory Carlson</td>
            <td class="px-6 py-3">191-11</td>
            <td class="px-6 py-3">1993-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">High Jump Relay</td>
            <td class="px-6 py-3">
              P. Clevidence<br />
              A. Kirchenbauer<br />
              M. Taverna
            </td>
            <td class="px-6 py-3">17-10.00</td>
            <td class="px-6 py-3">1979-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">Long Jump Relay</td>
            <td class="px-6 py-3">
              T. Bittner<br />
              D. Kauf<br />
              J. Snell
            </td>
            <td class="px-6 py-3">63-06.00</td>
            <td class="px-6 py-3">1975-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Pole Vault Relay</td>
            <td class="px-6 py-3">
              Chris Lamphier<br />
              Jake Lile<br />
              Josh Newcomer
            </td>
            <td class="px-6 py-3">36-00.00</td>
            <td class="px-6 py-3">2007-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">Shot Put Relay</td>
            <td class="px-6 py-3">
              Joel Belding<br />
              Alex Drollinger<br />
              P. Hannen
            </td>
            <td class="px-6 py-3">150-01.00</td>
            <td class="px-6 py-3">2003-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Discus Relay</td>
            <td class="px-6 py-3">
              Joel Belding<br />
              Brian Dove<br />
              Greg Zartman
            </td>
            <td class="px-6 py-3">445-07</td>
            <td class="px-6 py-3">2004-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">2K Steeple Chase</td>
            <td class="px-6 py-3">Sean Hutson</td>
            <td class="px-6 py-3">06:31.00</td>
            <td class="px-6 py-3">2005-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Hammer Throw</td>
            <td class="px-6 py-3">Greg Zartman</td>
            <td class="px-6 py-3">141-04</td>
            <td class="px-6 py-3">2004-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">Hammer Throw Relay</td>
            <td class="px-6 py-3">
              Steve Dembowski<br />
              Adam Dove<br />
              Brian Livingstone
            </td>
            <td class="px-6 py-3">258-03</td>
            <td class="px-6 py-3">2011-03-26</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Triple Jump</td>
            <td class="px-6 py-3">Cory Beebe</td>
            <td class="px-6 py-3">39-04.25</td>
            <td class="px-6 py-3">2005-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">Triple Jump Relay</td>
            <td class="px-6 py-3">
              Jesse Agnes<br />
              Zach Miglich<br />
              Ryan Pistor
            </td>
            <td class="px-6 py-3">100-11.25</td>
            <td class="px-6 py-3">2011-03-26</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Seated 100M</td>
            <td class="px-6 py-3">&nbsp;</td>
            <td class="px-6 py-3">&nbsp;</td>
            <td class="px-6 py-3">&nbsp;</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">Seated 200M</td>
            <td class="px-6 py-3">&nbsp;</td>
            <td class="px-6 py-3">&nbsp;</td>
            <td class="px-6 py-3">&nbsp;</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Seated 400M</td>
            <td class="px-6 py-3">&nbsp;</td>
            <td class="px-6 py-3">&nbsp;</td>
            <td class="px-6 py-3">&nbsp;</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">Seated 800M</td>
            <td class="px-6 py-3">&nbsp;</td>
            <td class="px-6 py-3">&nbsp;</td>
            <td class="px-6 py-3">&nbsp;</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Seated Shot Put</td>
            <td class="px-6 py-3">&nbsp;</td>
            <td class="px-6 py-3">&nbsp;</td>
            <td class="px-6 py-3">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="4" class="px-6 py-3 text-center dark:bg-green-800 dark:bg-opacity-50 bg-gray-300 bg-opacity-50">
              Indoor
            </td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">60M</td>
            <td class="px-6 py-3">Zack Munchick</td>
            <td class="px-6 py-3">00:07.42</td>
            <td class="px-6 py-3">2017-02-04</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">200M</td>
            <td class="px-6 py-3">Blake Wilson</td>
            <td class="px-6 py-3">00:24.01</td>
            <td class="px-6 py-3">2021-02-20</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">400M</td>
            <td class="px-6 py-3">Andrew Hibler</td>
            <td class="px-6 py-3">00:55.88</td>
            <td class="px-6 py-3">2021-02-15</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">800M</td>
            <td class="px-6 py-3">Luke Malena</td>
            <td class="px-6 py-3">02:12.27</td>
            <td class="px-6 py-3">2021-01-30</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">1600M</td>
            <td class="px-6 py-3">Brett Gucker</td>
            <td class="px-6 py-3">05:03.98</td>
            <td class="px-6 py-3">2021-02-15</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">3200M</td>
            <td class="px-6 py-3">Max Gucker</td>
            <td class="px-6 py-3">09:38.48</td>
            <td class="px-6 py-3">2020-02-22</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">60M Hurdles</td>
            <td class="px-6 py-3">&nbsp;</td>
            <td class="px-6 py-3">&nbsp;</td>
            <td class="px-6 py-3">&nbsp;</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">4x400M Relay</td>
            <td class="px-6 py-3">
              &nbsp;<br />
              &nbsp;<br />
              &nbsp;<br />
              &nbsp;
            </td>
            <td class="px-6 py-3">&nbsp;</td>
            <td class="px-6 py-3">&nbsp;</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">4x800M Relay</td>
            <td class="px-6 py-3">
              Nolan Freeland<br />
              Brett Gucker<br />
              Andrew Hibler<br />
              Cole Yagersz              
            </td>
            <td class="px-6 py-3">09:38.48</td>
            <td class="px-6 py-3">2020-02-21</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Long Jump</td>
            <td class="px-6 py-3">Adam Oren</td>
            <td class="px-6 py-3">16-09.00</td>
            <td class="px-6 py-3">2017-02-04</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">Shot Put</td>
            <td class="px-6 py-3">Brian Livingstone</td>
            <td class="px-6 py-3">45-10.00</td>
            <td class="px-6 py-3">2012-02-05</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Triple Jump</td>
            <td class="px-6 py-3">&nbsp;</td>
            <td class="px-6 py-3">&nbsp;</td>
            <td class="px-6 py-3">&nbsp;</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">Seated Shot Put</td>
            <td class="px-6 py-3">&nbsp;</td>
            <td class="px-6 py-3">&nbsp;</td>
            <td class="px-6 py-3">&nbsp;</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex-auto mr-1">
      <h2 class="dark:text-gray-200 text-center text-3xl font-bold mb-2">
        Girls
      </h2>
      <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-800 text-xs font-medium dark:text-white text-gray-500 tracking-wider">
        <thead class="bg-gray-50 dark:bg-black dark:bg-opacity-50 text-left">
          <tr>
            <th scope="col" class="px-6 py-3 w-24">
              Event
            </th>
            <th scope="col" class="px-6 py-3 w-48">
              Athlete
            </th>
            <th scope="col" class="px-6 py-3 w-20">
              Performance
            </th>
            <th scope="col" class="px-6 py-3">
              Date
            </th>
          </tr>
        </thead>
        <tbody class="text-left">
          <tr>
            <td colspan="4" class="px-6 py-3 border-t-2 dark:border-black border-gray-200 text-center dark:bg-green-800 dark:bg-opacity-50 bg-gray-300 bg-opacity-50">
              Outdoor
            </td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">100M</td>
            <td class="px-6 py-3">Riley Evans</td>
            <td class="px-6 py-3">00:12.40</td>
            <td class="px-6 py-3">2021-04-20</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">200M</td>
            <td class="px-6 py-3">Mandy Fugate</td>
            <td class="px-6 py-3">00:25.90</td>
            <td class="px-6 py-3">2001-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">400M</td>
            <td class="px-6 py-3">Riley Evans</td>
            <td class="px-6 py-3">00:57.43</td>
            <td class="px-6 py-3">2021-06-05</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">800M</td>
            <td class="px-6 py-3">Hillary Hewit</td>
            <td class="px-6 py-3">02:13.62</td>
            <td class="px-6 py-3">2004-05-27</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">1600M</td>
            <td class="px-6 py-3">Riley Freeland</td>
            <td class="px-6 py-3">05:03.15</td>
            <td class="px-6 py-3">2016-06-03</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">3200M</td>
            <td class="px-6 py-3">Ashley Murray</td>
            <td class="px-6 py-3">11:04.00</td>
            <td class="px-6 py-3">2013-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">100M Hurdles</td>
            <td class="px-6 py-3">Jennifer Foster</td>
            <td class="px-6 py-3">00:14.82</td>
            <td class="px-6 py-3">2011-05-25</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">300M Hurdles</td>
            <td class="px-6 py-3">Kelani Nuckols</td>
            <td class="px-6 py-3">00:46.10</td>
            <td class="px-6 py-3">2011-05-25</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">4x100M Relay</td>
            <td class="px-6 py-3">
              Maddie Boltz<br />
              Nyah Hansen<br />
              Paige Munchick<br />
              Emily Romano
            </td>
            <td class="px-6 py-3">00:50.85</td>
            <td class="px-6 py-3">2019-05-23</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">4x200M Relay</td>
            <td class="px-6 py-3">
              Julia Armogida<br />
              Riley Evans<br />
              Paige Munchick<br />
              Emily Romano
            </td>
            <td class="px-6 py-3">01:44.73</td>
            <td class="px-6 py-3">2021-05-21</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">4x400M Relay</td>
            <td class="px-6 py-3">
              Lauren Frank<br />
              Riley Freeland<br />
              Erian Hamilton<br />
              Jenna Oliver
            </td>
            <td class="px-6 py-3">04:01.96</td>
            <td class="px-6 py-3">2016-05-28</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">4x800M Relay</td>
            <td class="px-6 py-3">
              Riley Freeland<br />
              Tommie Kurtz<br />
              Jenna Oliver<br />
              Vanessa Rivera
            </td>
            <td class="px-6 py-3">09:26.32</td>
            <td class="px-6 py-3">2016-05-28</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">4x1600M Relay</td>
            <td class="px-6 py-3">
              C. Ocheltree<br />
              Kim Malarkey<br />
              T. Malarkey<br />
              T. Surrarrer
            </td>
            <td class="px-6 py-3">21:56.70</td>
            <td class="px-6 py-3">1990-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Sprint Medley</td>
            <td class="px-6 py-3">
              Riley Evans<br />
              Emma Malikowski<br />
              Paige Munchick<br />
              Emily Romano
            </td>
            <td class="px-6 py-3">01:53.34</td>
            <td class="px-6 py-3">2021-04-10</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">Distance Medley</td>
            <td class="px-6 py-3">
              Catie Coleman<br />
              Hillary Hewit<br />
              Jenny Rupp<br />
              Heidi Santelmann<br />
            </td>
            <td class="px-6 py-3">12:49.90</td>
            <td class="px-6 py-3">2005-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">4x100M Shuttle Hurdles</td>
            <td class="px-6 py-3">
              Stephanie Foster<br />
              Erin Mohney<br />
              Jenny Rupp<br />
              Heidi Santelmann
            </td>
            <td class="px-6 py-3">01:06.10</td>
            <td class="px-6 py-3">2007-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">4x300M Shuttle Hurdles</td>
            <td class="px-6 py-3">
              &nbsp;<br />
              &nbsp;<br />
              &nbsp;<br />
              &nbsp;
            </td>
            <td class="px-6 py-3"></td>
            <td class="px-6 py-3"></td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">4x100M Weight Relay</td>
            <td class="px-6 py-3">
              Harper Owens<br />
              Lisa Wangler<br />
              Brooke Wolfe<br />
              Katelyn Young
            </td>
            <td class="px-6 py-3">01:01.34</td>
            <td class="px-6 py-3">2015-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">High Jump</td>
            <td class="px-6 py-3">Jennifer Foster</td>
            <td class="px-6 py-3">05-09.00</td>
            <td class="px-6 py-3">2011-05-25</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Long Jump</td>
            <td class="px-6 py-3">Janelle Shaffer</td>
            <td class="px-6 py-3">17-06.00</td>
            <td class="px-6 py-3">2011-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">Pole Vault</td>
            <td class="px-6 py-3">Jennifer Foster</td>
            <td class="px-6 py-3">12-02.00</td>
            <td class="px-6 py-3">2011-06-03</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Shot Put</td>
            <td class="px-6 py-3">B. Elkevizth</td>
            <td class="px-6 py-3">41-02.00</td>
            <td class="px-6 py-3">1999-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">Discus</td>
            <td class="px-6 py-3">Katelyn Young</td>
            <td class="px-6 py-3">135-02</td>
            <td class="px-6 py-3">2017-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">High Jump Relay</td>
            <td class="px-6 py-3">
              Amber Hansen<br />
              Caitlyn Kilkenny<br />
              Jenny Rupp
            </td>
            <td class="px-6 py-3">14-06.00</td>
            <td class="px-6 py-3">2005-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">Long Jump Relay</td>
            <td class="px-6 py-3">
              Laurell Brown<br />
              Emma Malikowski<br />
              Ashley Sigmon
            </td>
            <td class="px-6 py-3">46-06.75</td>
            <td class="px-6 py-3">2021-04-23</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Pole Vault Relay</td>
            <td class="px-6 py-3">
              Jennifer Foster<br />
              Stephanie Foster<br />
              Paige Lloyd
            </td>
            <td class="px-6 py-3">30-00.00</td>
            <td class="px-6 py-3">2009-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">Shot Put Relay</td>
            <td class="px-6 py-3">
              B. Elkevizth<br />
              A. Lutton<br />
              C. White
            </td>
            <td class="px-6 py-3">103-05.50</td>
            <td class="px-6 py-3">1999-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Discus Relay</td>
            <td class="px-6 py-3">
              Megan Blanton<br />
              Allison Early<br />
              Cassandra Maxwell
            </td>
            <td class="px-6 py-3">327-00</td>
            <td class="px-6 py-3">2006-04-29</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">2K Steeple Chase</td>
            <td class="px-6 py-3">Catie Coleman</td>
            <td class="px-6 py-3">08:10.00</td>
            <td class="px-6 py-3">2004-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Hammer Throw</td>
            <td class="px-6 py-3">Cassandra Maxwell</td>
            <td class="px-6 py-3">83-03</td>
            <td class="px-6 py-3">2006-05-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">Hammer Throw Relay</td>
            <td class="px-6 py-3">
              Angela Landrum<br />
              Kylar Nanna<br />
              Katie Simpson
            </td>
            <td class="px-6 py-3">167-10</td>
            <td class="px-6 py-3">2011-03-26</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Triple Jump</td>
            <td class="px-6 py-3">&nbsp;</td>
            <td class="px-6 py-3">&nbsp;</td>
            <td class="px-6 py-3">&nbsp;</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">Triple Jump Relay</td>
            <td class="px-6 py-3">
              &nbsp;<br />
              &nbsp;<br />
              &nbsp;
            </td>
            <td class="px-6 py-3">&nbsp;</td>
            <td class="px-6 py-3">&nbsp;</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Seated 100M</td>
            <td class="px-6 py-3">Brooklynn Crist</td>
            <td class="px-6 py-3">00:20.29</td>
            <td class="px-6 py-3">2021-05-07</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">Seated 200M</td>
            <td class="px-6 py-3">Brooklynn Crist</td>
            <td class="px-6 py-3">00:40.20</td>
            <td class="px-6 py-3">2021-05-04</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Seated 400M</td>
            <td class="px-6 py-3">Brooklynn Crist</td>
            <td class="px-6 py-3">01:15.91</td>
            <td class="px-6 py-3">2018-06-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">Seated 800M</td>
            <td class="px-6 py-3">Brooklynn Crist</td>
            <td class="px-6 py-3">02:44.11</td>
            <td class="px-6 py-3">2018-06-01</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Seated Shot Put</td>
            <td class="px-6 py-3">Brooklynn Crist</td>
            <td class="px-6 py-3">16-10.00</td>
            <td class="px-6 py-3">2021-06-05</td>
          </tr>
          <tr>
            <td colspan="4" class="px-6 py-3 text-center dark:bg-green-800 dark:bg-opacity-50 bg-gray-300 bg-opacity-50">
              Indoor
            </td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">60M</td>
            <td class="px-6 py-3">Emily Romano</td>
            <td class="px-6 py-3">00:08.05</td>
            <td class="px-6 py-3">2021-01-30</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">200M</td>
            <td class="px-6 py-3">Emily Romano</td>
            <td class="px-6 py-3">00:28.24</td>
            <td class="px-6 py-3">2021-01-30</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">400M</td>
            <td class="px-6 py-3">Riley Evans</td>
            <td class="px-6 py-3">01:04.70</td>
            <td class="px-6 py-3">2020-12-29</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">800M</td>
            <td class="px-6 py-3">Alyssa Stoner</td>
            <td class="px-6 py-3">02:25.94</td>
            <td class="px-6 py-3">2021-02-20</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">1600M</td>
            <td class="px-6 py-3">Sophia Spencer</td>
            <td class="px-6 py-3">05:26.22</td>
            <td class="px-6 py-3">2021-01-30</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">3200M</td>
            <td class="px-6 py-3">Sophia Spencer</td>
            <td class="px-6 py-3">11:33.45</td>
            <td class="px-6 py-3">2021-03-06</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">60M Hurdles</td>
            <td class="px-6 py-3">Zana Ross</td>
            <td class="px-6 py-3">00:10.03</td>
            <td class="px-6 py-3">2016-02-06</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">4x400M Relay</td>
            <td class="px-6 py-3">
              Riley Evans<br />
              Paige Munchick<br />
              Emily Romano<br />
              Alyssa Stoner
            </td>
            <td class="px-6 py-3">04:24.27</td>
            <td class="px-6 py-3">2021-03-06</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">4x800M Relay</td>
            <td class="px-6 py-3">
              Paige Munchick<br />
              Maria Porras<br />
              Sophia Spencer<br />
              Alyssa Stoner
            </td>
            <td class="px-6 py-3">10:16.69</td>
            <td class="px-6 py-3">2021-03-06</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Long Jump</td>
            <td class="px-6 py-3">Kelani Nuckols</td>
            <td class="px-6 py-3">16-05.50</td>
            <td class="px-6 py-3">2012-02-05</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">Shot Put</td>
            <td class="px-6 py-3">Katelyn Young</td>
            <td class="px-6 py-3">31-09.50</td>
            <td class="px-6 py-3">2017-02-04</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-25 bg-gray-300 bg-opacity-20">
            <td class="px-6 py-3">Triple Jump</td>
            <td class="px-6 py-3">Kelani Nuckols</td>
            <td class="px-6 py-3">34-04.25</td>
            <td class="px-6 py-3">2012-02-26</td>
          </tr>
          <tr class="dark:bg-green-900 dark:bg-opacity-10 bg-gray-300 bg-opacity-5">
            <td class="px-6 py-3">Seated Shot Put</td>
            <td class="px-6 py-3">Brooklynn Crist</td>
            <td class="px-6 py-3">15-03.25</td>
            <td class="px-6 py-3">2021-02-15</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>
