<template>
  <div class="prose prose-slate dark:text-white min-w-full">
    <h2 class="dark:text-gray-200 ">Welcome!</h2>
    <!-- <p>
      In total there are <strong class="dark:text-gray-200">xxxx</strong> performances for <strong class="dark:text-gray-200">xxx</strong> from <strong class="dark:text-gray-200">xxx</strong> meets across <strong class="dark:text-gray-200">xx</strong> seasons of Cloverleaf Track and Field.
    </p> -->
    <p>
      A lot of this website was manually updated, so if something seems wrong or out-of-place, it likely is! Please <a href="mailto:tony@morriscloud.com" class="dark:text-white">email me</a> if you notice anything needs corrected.
    </p>
  </div>
</template>

<script setup lang="ts">
</script>
